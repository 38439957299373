import * as React from 'react'
import { authRoles } from '../auth/auth'
import { Redirect } from 'react-router-dom'
import Utils from '../theme/utils'

import { Embed } from 'src/components/routes/embed'
import Loader from '../components/reusable/Loader'

const routeConfigs = [
  {
    settings: {
      layout: {
        config: {
          navbar: {
            display: false,
          },
          toolbar: {
            display: false,
          },
          footer: {
            display: false,
          },
        },
      },
    },
    auth: authRoles.isLoggedOut,
    routes: [
      {
        path: '/login',
        component: (props: any) => <Loader {...props} />,
      },
    ],
  },
  {
    settings: {
      layout: {
        config: {
          navbar: {
            display: false,
          },
          toolbar: {
            display: false,
          },
          footer: {
            display: false,
          },
        },
      },
    },
    auth: authRoles.isLoggedOut,
    routes: [
      {
        path: '/auto-login',
        component: (props: any) => <Loader {...props} />,
      },
    ],
  },
  {
    settings: {
      layout: {
        config: {
          navbar: {
            display: false,
          },
          toolbar: {
            display: false,
          },
          footer: {
            display: false,
          },
        },
      },
    },
    auth: authRoles.isLoggedOut,
    routes: [
      {
        path: '/login-lp',
        component: (props: any) => <Loader {...props} />,
      },
    ],
  },
  {
    settings: {
      layout: {
        config: {
          navbar: {
            display: false,
          },
          toolbar: {
            display: false,
          },
          footer: {
            display: false,
          },
        },
      },
    },
    auth: authRoles.isLoggedOut,
    routes: [
      {
        path: '/register',
        component: (props: any) => <Loader {...props} />,
      },
    ],
  },
  {
    settings: {
      layout: {
        config: {
          navbar: {
            display: false,
          },
          toolbar: {
            display: false,
          },
          footer: {
            display: false,
          },
        },
      },
    },
    auth: authRoles.isLoggedOut,
    routes: [
      {
        path: '/reset-password',
        component: (props: any) => <Loader {...props} />,
      },
    ],
  },
  {
    settings: {
      layout: {
        config: {
          navbar: {
            display: false,
          },
          toolbar: {
            display: false,
          },
          footer: {
            display: false,
          },
        },
      },
    },
    auth: authRoles.isLoggedOut,
    routes: [
      {
        path: '/forgot-password',
        component: (props: any) => <Loader {...props} />,
      },
    ],
  },
  {
    settings: {
      layout: {
        config: {
          navbar: {
            display: false,
          },
          toolbar: {
            display: false,
          },
          footer: {
            display: false,
          },
        },
      },
    },
    auth: authRoles.isLoggedOut,
    routes: [
      {
        path: '/lp',
        component: (props: any) => <Loader {...props} />,
      },
    ],
  },
  {
    auth: authRoles.isLoggedIn,
    routes: [
      {
        path: '/home',
        component: (props: any) => <Loader {...props} />,
      },
    ],
  },
  {
    auth: authRoles.isLoggedIn,
    routes: [
      {
        path: '/support/chat',
        component: (props: any) => <Loader {...props} />,
      },
    ],
  },
  {
    auth: authRoles.isLoggedIn,
    routes: [
      {
        path: '/channel/create',
        component: (props: any) => <Loader {...props} />,
      },
    ],
  },
  {
    auth: authRoles.isLoggedIn,
    routes: [
      {
        path: '/channel/edit/:channelId',
        component: (props: any) => <Loader {...props} />,
      },
    ],
  },
  {
    auth: authRoles.isLoggedIn,
    routes: [
      {
        path: '/channel/:channelId/event-log',
        component: (props: any) => <Loader {...props} />,
      },
    ],
  },

  {
    auth: authRoles.isLoggedIn,
    routes: [
      {
        path: '/edit/video/:videoId/:version/:section/event-log',
        component: (props: any) => <Loader {...props} />,
      },
    ],
  },
  {
    auth: authRoles.isLoggedIn,
    routes: [
      {
        path: '/channel/access-control/:channelId',
        component: (props: any) => <Loader {...props} />,
      },
    ],
  },
  {
    auth: authRoles.isLoggedIn,
    routes: [
      {
        path: '/channel/tags/:channelId',
        component: (props: any) => <Loader {...props} />,
      },
    ],
  },
  {
    auth: authRoles.isLoggedIn,
    routes: [
      {
        path: '/channel/recommendation/:channelId',
        component: (props: any) => <Loader {...props} />,
      },
    ],
  },
  // {
  //   auth: authRoles.isLoggedIn,
  //   routes: [
  //     {
  //       path: '/channel/users/:channelId',
  //       component: (props: any) => <Loader {...props} />,
  //     },
  //   ],
  // },

  {
    auth: authRoles.isLoggedIn,
    routes: [
      {
        path: '/channel/:channelId/home',
        component: (props: any) => <Loader {...props} />,
      },
    ],
  },
  {
    auth: authRoles.isLoggedIn,
    routes: [
      {
        path: '/channel/:channelId/videos',
        component: (props: any) => <Loader {...props} />,
      },
    ],
  },
  {
    auth: authRoles.isLoggedIn,
    routes: [
      {
        path: '/channel/:channelId/users',
        component: (props: any) => <Loader {...props} />,
      },
    ],
  },
  {
    auth: authRoles.isLoggedIn,
    routes: [
      {
        path: '/channel/:channelId/tracks',
        component: (props: any) => <Loader {...props} />,
      },
    ],
  },
  {
    auth: authRoles.isLoggedIn,
    routes: [
      {
        path: '/channel/:channelId/playlists',
        component: (props: any) => <Loader {...props} />,
      },
    ],
  },
  {
    auth: authRoles.isLoggedIn,
    routes: [
      {
        path: '/channel/:channelId/tasks',
        component: (props: any) => <Loader {...props} />,
      },
    ],
  },
  {
    auth: authRoles.isLoggedIn,
    routes: [
      {
        path: '/channel/:channelId/reports/shared',
        component: (props: any) => <Loader {...props} />,
      },
    ],
  },
  {
    auth: authRoles.isLoggedIn,
    routes: [
      {
        path: '/channel/:channelId/reports',
        component: (props: any) => <Loader {...props} />,
      },
    ],
  },
  {
    auth: authRoles.isLoggedIn,
    routes: [
      {
        path: '/channel/:channelId/playlist/:trackId/share',
        component: (props: any) => <Loader {...props} />,
      },
    ],
  },
  {
    auth: authRoles.isLoggedIn,
    routes: [
      {
        path: '/channel/:channelId/playlist/:trackId/create-video',
        component: (props: any) => <Loader {...props} />,
      },
    ],
  },
  // {
  //   auth: authRoles.isAdmin,
  //   routes: [
  //     {
  //       path: '/channel/:channelId/promotions',
  //       component: (props: any) => <Loader {...props} />,
  //     },
  //   ],
  // },
  {
    auth: authRoles.isLoggedIn,
    routes: [
      {
        path: '/channel/:redirectId',
        component: (props: any) => <Loader {...props} />,
      },
    ],
  },
  {
    auth: authRoles.isLoggedIn,
    routes: [
      {
        path: '/track/create',
        component: (props: any) => <Loader {...props} />,
      },
    ],
  },
  {
    auth: authRoles.isLoggedIn,
    routes: [
      {
        path: '/playlist/create/:trackId',
        component: (props: any) => <Loader {...props} />,
      },
    ],
  },
  {
    auth: authRoles.isLoggedIn,
    routes: [
      {
        path: '/playlist/create',
        component: (props: any) => <Loader {...props} />,
      },
    ],
  },

  {
    auth: authRoles.isLoggedIn,
    routes: [
      {
        path: '/track/edit/:trackId',
        component: (props: any) => <Loader {...props} />,
      },
    ],
  },
  {
    auth: authRoles.isLoggedIn,
    routes: [
      {
        path: '/playlist/edit/:trackId/translations',
        component: (props: any) => <Loader {...props} />,
      },
    ],
  },
  {
    auth: authRoles.isLoggedIn,
    routes: [
      {
        path: '/playlist/edit/:trackId/share',
        component: (props: any) => <Loader {...props} />,
      },
    ],
  },
  {
    auth: authRoles.isLoggedIn,
    routes: [
      {
        path: '/playlist/edit/:trackId',
        component: (props: any) => <Loader {...props} />,
      },
    ],
  },

  {
    auth: authRoles.isLoggedIn,
    routes: [
      {
        path: '/track/users/:trackId',
        component: (props: any) => <Loader {...props} />,
      },
    ],
  },
  {
    auth: authRoles.isLoggedIn,
    routes: [
      {
        path: '/playlist/users/:trackId',
        component: (props: any) => <Loader {...props} />,
      },
    ],
  },
  {
    auth: authRoles.isLoggedIn,
    routes: [
      {
        path: '/video/:humanId/:version/:lang',
        component: (props: any) => <Loader {...props} />,
      },
    ],
  },
  {
    auth: authRoles.isLoggedIn,
    routes: [
      {
        path: '/produce-video/:taskId',
        component: (props: any) => <Loader {...props} />,
      },
    ],
  },
  {
    auth: authRoles.isLoggedIn,
    routes: [
      {
        path: '/video/:humanId',
        component: (props: any) => <Loader {...props} />,
      },
    ],
  },
  {
    auth: authRoles.isLoggedIn,
    routes: [
      {
        path: '/create/video',
        component: (props: any) => <Loader {...props} />,
      },
    ],
  },
  {
    auth: authRoles.isLoggedIn,
    routes: [
      {
        path: '/edit/video/overview/:videoId/:version/:section',
        component: (props: any) => <Loader {...props} />,
      },
    ],
  },
  {
    auth: authRoles.isLoggedIn,
    routes: [
      {
        path: '/edit/video/tags/:videoId/:version/:section',
        component: (props: any) => <Loader {...props} />,
      },
    ],
  },
  {
    auth: authRoles.isLoggedIn,
    routes: [
      {
        path: '/edit/video/reports/:videoId/:version/:section',
        component: (props: any) => <Loader {...props} />,
      },
    ],
  },
  {
    auth: authRoles.isLoggedIn,
    routes: [
      {
        path: '/edit/video/shared/reports/:videoId/:version/:section',
        component: (props: any) => <Loader {...props} />,
      },
    ],
  },
  {
    auth: authRoles.isLoggedIn,
    routes: [
      {
        path: '/edit/video/ask-for-review/:videoId/:version/:section',
        component: (props: any) => <Loader {...props} />,
      },
    ],
  },
  {
    auth: authRoles.isLoggedIn,
    routes: [
      {
        path: '/edit/video/embed-codes/:videoId/:version/:section',
        component: (props: any) => <Loader {...props} />,
      },
    ],
  },
  {
    auth: authRoles.isLoggedIn,
    routes: [
      {
        path: '/edit/video/editor/:videoId/:version/:section/voice-over',
        component: (props: any) => <Loader {...props} />,
      },
    ],
  },
  {
    auth: authRoles.isLoggedIn,
    routes: [
      {
        path: '/edit/video/editor/:videoId/:version/:section/upload-files',
        component: (props: any) => <Loader {...props} />,
      },
    ],
  },
  {
    auth: authRoles.isLoggedIn,
    routes: [
      {
        path: '/edit/video/editor/:videoId/:version/:section/effects',
        component: (props: any) => <Loader {...props} />,
      },
    ],
  },
  {
    auth: authRoles.isLoggedIn,
    routes: [
      {
        path: '/edit/video/editor/:videoId/:version/:section/manuscript',
        component: (props: any) => <Loader {...props} />,
      },
    ],
  },
  {
    auth: authRoles.isLoggedIn,
    routes: [
      {
        path: '/edit/video/editor/:videoId/:version/:section/quick-guide',
        component: (props: any) => <Loader {...props} />,
      },
    ],
  },
  {
    auth: authRoles.isLoggedIn,
    routes: [
      {
        path: '/edit/video/editor/:videoId/:version/:section/links',
        component: (props: any) => <Loader {...props} />,
      },
    ],
  },
  {
    auth: authRoles.isLoggedIn,
    routes: [
      {
        path: '/edit/video/translator/:videoId/:version/:section/title',
        component: (props: any) => <Loader {...props} />,
      },
    ],
  },
  {
    auth: authRoles.isLoggedIn,
    routes: [
      {
        path: '/edit/video/translator/:videoId/:version/:section/manuscript',
        component: (props: any) => <Loader {...props} />,
      },
    ],
  },
  {
    auth: authRoles.isLoggedIn,
    routes: [
      {
        path: '/edit/video/translator/:videoId/:version/:section/quick-guide',
        component: (props: any) => <Loader {...props} />,
      },
    ],
  },
  {
    auth: authRoles.isLoggedIn,
    routes: [
      {
        path: '/edit/video/translator/:videoId/:version/:section/links',
        component: (props: any) => <Loader {...props} />,
      },
    ],
  },
  {
    auth: authRoles.isLoggedIn,
    routes: [
      {
        path: '/edit/video/translator/:videoId/:version/:section/caption',
        component: (props: any) => <Loader {...props} />,
      },
    ],
  },
  {
    auth: authRoles.isLoggedIn,
    routes: [
      {
        path: '/edit/video/translator/:videoId/:version/:section/effects',
        component: (props: any) => <Loader {...props} />,
      },
    ],
  },
  {
    auth: authRoles.isLoggedIn,
    routes: [
      {
        path: '/edit/video/translator/:videoId/:version/:section/voice-annotation',
        component: (props: any) => <Loader {...props} />,
      },
    ],
  },
  {
    auth: authRoles.isLoggedIn,
    routes: [
      {
        path: '/edit/video/translator/:videoId/:version/:section/upload-files',
        component: (props: any) => <Loader {...props} />,
      },
    ],
  },
  {
    auth: authRoles.isLoggedIn,
    routes: [
      {
        path: '/admin/videos',
        component: (props: any) => <Loader {...props} />,
      },
    ],
  },
  {
    auth: authRoles.isLoggedIn,
    routes: [
      {
        path: '/admin/branding',
        component: (props: any) => <Loader {...props} />,
      },
    ],
  },
  {
    auth: authRoles.isLoggedIn,
    routes: [
      {
        path: '/admin/organization',
        component: (props: any) => <Loader {...props} />,
      },
    ],
  },
  {
    auth: authRoles.isLoggedIn,
    routes: [
      {
        path: '/admin/security',
        component: (props: any) => <Loader {...props} />,
      },
    ],
  },
  {
    auth: authRoles.isLoggedIn,
    routes: [
      {
        path: '/admin/channels',
        component: (props: any) => <Loader {...props} />,
      },
    ],
  },
  {
    auth: authRoles.isLoggedIn,
    routes: [
      {
        path: '/admin/tracks',
        component: (props: any) => <Loader {...props} />,
      },
    ],
  },
  {
    auth: authRoles.isLoggedIn,
    routes: [
      {
        path: '/admin/playlists',
        component: (props: any) => <Loader {...props} />,
      },
    ],
  },
  {
    auth: authRoles.isAdmin,
    routes: [
      {
        path: '/admin/tasks',
        component: (props: any) => <Loader {...props} />,
      },
    ],
  },
  {
    auth: authRoles.isAdmin,
    routes: [
      {
        path: '/admin/operationalTasks',
        component: (props: any) => <Loader {...props} />,
      },
    ],
  },
  {
    auth: authRoles.isAdmin,
    routes: [
      {
        path: '/admin/users/usage',
        component: (props: any) => <Loader {...props} />,
      },
    ],
  },
  {
    auth: authRoles.isLoggedIn,
    routes: [
      {
        path: '/admin/users',
        component: (props: any) => <Loader {...props} />,
      },
    ],
  },
  {
    auth: authRoles.isLoggedIn,
    routes: [
      {
        path: '/admin/azure-ad',
        component: (props: any) => <Loader {...props} />,
      },
    ],
  },
  {
    auth: authRoles.isLoggedIn,
    routes: [
      {
        path: '/admin/reports/shared',
        component: (props: any) => <Loader {...props} />,
      },
    ],
  },
  {
    auth: authRoles.isLoggedIn,
    routes: [
      {
        path: '/admin/reports',
        component: (props: any) => <Loader {...props} />,
      },
    ],
  },

  {
    auth: authRoles.isLoggedIn,
    routes: [
      {
        path: '/admin/tags',
        component: (props: any) => <Loader {...props} />,
      },
    ],
  },
  // {
  //   auth: authRoles.isAdmin,
  //   routes: [
  //     {
  //       path: '/finance/internal-users',
  //       component: (props: any) => <Loader {...props} />,
  //     },
  //   ],
  // },
  // {
  //   auth: authRoles.isAdmin,
  //   routes: [
  //     {
  //       path: '/finance/external-users',
  //       component: (props: any) => <Loader {...props} />,
  //     },
  //   ],
  // },
  {
    auth: authRoles.isLoggedIn,
    routes: [
      {
        path: '/admin/information-classification',
        component: (props: any) => <Loader {...props} />,
      },
    ],
  },
  // {
  //   auth: authRoles.isAdmin,
  //   routes: [
  //     {
  //       path: '/finance/video-rendering',
  //       component: (props: any) => <Loader {...props} />,
  //     },
  //   ],
  // },
  // {
  //   auth: authRoles.isAdmin,
  //   routes: [
  //     {
  //       path: '/finance/anonymous-plays',
  //       component: (props: any) => <Loader {...props} />,
  //     },
  //   ],
  // },
  {
    auth: authRoles.isAdmin,
    routes: [
      {
        path: '/finance/features-services',
        component: (props: any) => <Loader {...props} />,
      },
    ],
  },
  {
    auth: authRoles.isLoggedIn,
    routes: [
      {
        path: '/subscriptions',
        component: (props: any) => <Loader {...props} />,
      },
    ],
  },
  {
    auth: authRoles.isLoggedIn,
    routes: [
      {
        path: '/favorites',
        component: (props: any) => <Loader {...props} />,
      },
    ],
  },
  {
    auth: authRoles.isLoggedIn,
    routes: [
      {
        path: '/tasks',
        component: (props: any) => <Loader {...props} />,
      },
    ],
  },
  {
    auth: authRoles.isLoggedIn,
    routes: [
      {
        path: '/notifications',
        component: (props: any) => <Loader {...props} />,
      },
    ],
  },
  {
    auth: authRoles.isLoggedIn,
    routes: [
      {
        path: '/my-videos',
        component: (props: any) => <Loader {...props} />,
      },
    ],
  },
  {
    auth: authRoles.isLoggedIn,
    routes: [
      {
        path: '/sharings',
        component: (props: any) => <Loader {...props} />,
      },
    ],
  },
  {
    auth: authRoles.isLoggedIn,
    routes: [
      {
        path: '/results',
        component: (props: any) => <Loader {...props} />,
      },
    ],
  },
  {
    auth: authRoles.isLoggedIn,
    routes: [
      {
        path: '/history',
        component: (props: any) => <Loader {...props} />,
      },
    ],
  },
  {
    auth: authRoles.isLoggedIn,
    routes: [
      {
        path: '/library',
        component: (props: any) => <Loader {...props} />,
      },
    ],
  },
  {
    auth: authRoles.isLoggedIn,
    routes: [
      {
        path: '/mandatory',
        component: (props: any) => <Loader {...props} />,
      },
    ],
  },
  {
    auth: authRoles.isAdmin,
    routes: [
      {
        path: '/screen-recorder/',
        component: () => <Redirect to="/home" />,
      },
    ],
  },
  {
    auth: authRoles.isAdmin,
    routes: [
      {
        path: '/orgs/usage',
        component: (props: any) => <Loader {...props} />,
      },
    ],
  },
  {
    auth: authRoles.isAdmin,
    routes: [
      {
        path: '/orgs/crm',
        component: (props: any) => <Loader {...props} />,
      },
    ],
  },
  {
    auth: authRoles.isAdmin,
    routes: [
      {
        path: '/orgs',
        component: (props: any) => <Loader {...props} />,
      },
    ],
  },

  {
    auth: authRoles.isAdmin,
    routes: [
      {
        path: '/screen-recorder/recording-options',
        component: () => <Redirect to="/home" />,
      },
    ],
  },
  {
    auth: authRoles.isAdmin,
    routes: [
      {
        path: '/screen-recorder/preview-recording',
        component: () => <Redirect to="/home" />,
      },
    ],
  },
  {
    auth: authRoles.isAdmin,
    routes: [
      {
        path: '/screen-recorder/recording',
        component: () => <Redirect to="/home" />,
      },
    ],
  },
  {
    auth: authRoles.isAdmin,
    routes: [
      {
        path: '/screen-recorder/upload-success/:videoId',
        component: () => <Redirect to="/home" />,
      },
    ],
  },
  {
    settings: {
      layout: {
        config: {
          navbar: {
            display: false,
          },
          toolbar: {
            display: false,
          },
          footer: {
            display: false,
          },
        },
      },
    },
    routes: [
      {
        path: '/embed/:key',
        component: Embed,
      },
    ],
  },
  {
    settings: {
      layout: {
        config: {
          navbar: {
            display: false,
          },
          toolbar: {
            display: false,
          },
          footer: {
            display: false,
          },
        },
      },
    },
    routes: [
      {
        path: '/sharing/embed/:key',
        component: Embed,
      },
    ],
  },
  {
    settings: {
      layout: {
        config: {
          navbar: {
            display: false,
          },
          toolbar: {
            display: false,
          },
          footer: {
            display: false,
          },
        },
      },
    },
    routes: [
      {
        path: '/sharing/:key',
        component: Embed,
      },
    ],
  },
  {
    settings: {
      layout: {
        config: {
          navbar: {
            display: false,
          },
          toolbar: {
            display: false,
          },
          footer: {
            display: false,
          },
        },
      },
    },
    auth: authRoles.isLoggedOut,
    routes: [
      {
        path: '/loginembed',
        component: (props: any) => <Loader {...props} />,
      },
    ],
  },
]

export const routes = [
  ...Utils.generateRoutesFromConfigs(routeConfigs),
  {
    path: '/',
    auth: authRoles.isLoggedIn,
    component: () => <Redirect to="/home" />,
  },
]
