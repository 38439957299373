import TaskTypes from 'src/enums/TaskTypes'
import TaskSubTypes, { OperationalSubTaskTypes } from 'src/enums/TaskSubTypes'
import { trackStatusToIntlMap, taskTypeToIntlMap, taskCategoryToIntlMap } from 'src/maps/tasks'
import TaskStatus from 'src/enums/TasksStatus'

export const initialFilterTasks = {
  category: [taskCategoryToIntlMap.mandatory, taskCategoryToIntlMap.recommended, taskCategoryToIntlMap.shared],
  type: [
    taskTypeToIntlMap[TaskTypes.WATCH_VIDEO],
    taskTypeToIntlMap[TaskTypes.OPERATIONAL],
    taskTypeToIntlMap[TaskTypes.GOVERNANCE],
  ],
  status: [
    trackStatusToIntlMap.notStarted,
    trackStatusToIntlMap.inProgress,
    trackStatusToIntlMap.completed,
    trackStatusToIntlMap.planned,
    trackStatusToIntlMap.overdue,
    trackStatusToIntlMap.canceled,
    trackStatusToIntlMap.skipped,
    trackStatusToIntlMap.requested,
  ],
  taskSubType: Object.values(TaskSubTypes),
  search: '',
}

export const defaultCheckedTasks = {
  category: [taskCategoryToIntlMap.mandatory, taskCategoryToIntlMap.recommended, taskCategoryToIntlMap.shared],
  type: [
    taskTypeToIntlMap[TaskTypes.WATCH_VIDEO],
    taskTypeToIntlMap[TaskTypes.OPERATIONAL],
    taskTypeToIntlMap[TaskTypes.GOVERNANCE],
  ],
  status: [
    trackStatusToIntlMap.notStarted,
    trackStatusToIntlMap.planned,
    trackStatusToIntlMap.inProgress,
    trackStatusToIntlMap.overdue,
  ],
  taskSubType: Object.values(TaskSubTypes),
  search: '',
}

export const editorList = [
  { url: 'effects', anchorText: 'add-chapters-effects', icon: 'exposure' },
  { url: 'manuscript', anchorText: 'add-manuscript-transcript', icon: 'toc' },
  { url: 'quick-guide', anchorText: 'quick-guide', icon: 'batch_prediction' },
  { url: 'links', anchorText: 'add-links', icon: 'link' },
  { url: 'voice-over', anchorText: 'voice-over', icon: 'mic_none' },
  { url: 'upload-files', anchorText: 'upload-files', icon: 'cloud_upload' },
]

export const advancedEditor = [
  { url: '/edit/video/overview/', anchorText: 'overview', icon: 'edit' },
  { url: '/edit/video/tags/', anchorText: 'tags', icon: 'local_offer' },
  { url: '/edit/video/embed-codes/', anchorText: 'embed-codes', icon: 'screen_share' },
]

export const translatorList = [
  { url: 'title', anchorText: 'translate-title-description', icon: 'title' },
  { url: 'effects', anchorText: 'effects', icon: 'exposure' },
  { url: 'manuscript', anchorText: 'manuscript-subtitles', icon: 'toc' },
  { url: 'quick-guide', anchorText: 'quick-guide', icon: 'batch_prediction' },
  { url: 'links', anchorText: 'add-links', icon: 'link' },
  { url: 'voice-annotation', anchorText: 'voice-over', icon: 'mic_none' },
  { url: 'upload-files', anchorText: 'upload-files', icon: 'cloud_upload' },
]

export const MINIMUM_CHAPTER_LENGTH = 200

// For this list we only show the list of languages that are supported by polly
export const TRAININGTUBE_SUPPORTED_LANGUAGES = [
  'en-us',
  'da',
  'sv',
  'no',
  'fi',
  'de',
  'zh',
  'ar',
  'nl',
  'fr-fr',
  'it',
  'hi',
  'ja',
  'ko',
  'pl',
  'pt-pt',
  'ru',
  'ro',
  'es-es',
  'es-mx',
  'tr',
  'is',
  'cy',
  'fr-ca',
  'en-in',
  'en-gb',
  'en-cy',
]

// This is the maximum depth of the playlist tree
export const MAXIMUM_TRACK_DEPTH = 7

export const TOOLBAR_BREAKPOINTS = {
  SMALL: 1040,
  MEDIUM: 1110,
  LARGE: 1235,
}

export const DEFAULT_CHAT_FILTERS = [TaskStatus.IN_PROGRESS, TaskStatus.NOT_STARTED, TaskStatus.OVERDUE]

export const USER_PAGE_TASK_SUB_TYPES = [
  OperationalSubTaskTypes.REGISTER_ACCOUNT,
  OperationalSubTaskTypes.LOGIN_FOR_FIRST_TIME,
  OperationalSubTaskTypes.SUPPORT_TICKET,
  OperationalSubTaskTypes.ADD_PLAYLIST_TO_CHANNEL,
  OperationalSubTaskTypes.ADD_VIDEO_PAGE,
  OperationalSubTaskTypes.UPLOAD_VIDEO,
  OperationalSubTaskTypes.REVIEW_OPTIMIZED_VIDEO,
  OperationalSubTaskTypes.REVIEW_VIDEO_TRANSLATION,
  OperationalSubTaskTypes.START_VIDEO_OPTIMIZATION,
  OperationalSubTaskTypes.PUBLISH_ORIGINAL_VIDEO,
  OperationalSubTaskTypes.RENDER_VIDEO,
  OperationalSubTaskTypes.REPLY_COMMENT_ON_VIDEO,
  OperationalSubTaskTypes.ALLOW_VIDEO_ACCESS,
  OperationalSubTaskTypes.ASSISTED_SEARCH,
  OperationalSubTaskTypes.SUPPORT_TICKET,
  OperationalSubTaskTypes.COMPLETE_VIDEO_OPTIMIZATION,
]

export const USER_PAGE_TASK_STATUS = [
  trackStatusToIntlMap.notStarted,
  trackStatusToIntlMap.inProgress,
  trackStatusToIntlMap.completed,
  trackStatusToIntlMap.planned,
  trackStatusToIntlMap.overdue,
  trackStatusToIntlMap.canceled,
  trackStatusToIntlMap.skipped,
  trackStatusToIntlMap.requested,
  trackStatusToIntlMap.rejected,
]

export const USER_PAGE_TASK_CATEGORIES = [
  taskCategoryToIntlMap.mandatory,
  taskCategoryToIntlMap.recommended,
  taskCategoryToIntlMap.shared,
]
export const USER_PAGE_TASK_TYPES = [
  taskTypeToIntlMap[TaskTypes.WATCH_VIDEO],
  taskTypeToIntlMap[TaskTypes.OPERATIONAL],
  taskTypeToIntlMap[TaskTypes.GOVERNANCE],
]

export const TASK_SUB_TYPES = Object.values(TaskSubTypes)

export const MAX_CHAT_INPUT_FIELD_HEIGHT = 200

export const NEW_MESSAGE_THRESHOLD_IN_MINUTES = 4

export const VIDEO_CONTAINER_MAX_WIDTH = 70
export const VIDEO_CONTAINER_MIN_WIDTH = 50
